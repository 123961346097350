<template>
  <div id="TermsAndConditions">
    <section class="section">
      <div class="container">
        <h1 class="title">{{ $t("terms.header") }}</h1>
        <div class="columns">
          <div class="column is-3 has-text-left">
            <b-menu>
              <b-menu-list>
                <b-menu-item
                  :active="this.$route.name === 'terms-and-condition'"
                  :label="$t('terms.header')"
                  tag="router-link"
                  :to="{ name: 'terms-and-condition' }"
                />
                <b-menu-item
                  :active="this.$route.name === 'privacy-policy'"
                  :label="$t('policy.header')"
                  tag="router-link"
                  :to="{ name: 'privacy-policy' }"
                />
              </b-menu-list>
            </b-menu>
          </div>
          <!-- T&C CONTENT -->
          <div v-if="$i18n.locale === 'th'" class="column">
            <section class="section has-text-left">
              <!-- เงื่อนไขการใช้งานเว็บไซต์ -->
              <p class="title is-4">เงื่อนไขการใช้งานเว็บไซต์</p>
              <p>
                โดยการเข้าถึงเว็บไซต์นี้ ท่านยืนยันว่าเข้าใจ
                "ข้อตกลงและเงื่อนไข" ทั้งหมดแล้ว
                หากท่านไม่ยอมรับข้อตกลงและเงื่อนไขเหล่านี้ในการใช้งาน
                ท่านจะไม่สามารถใช้เว็บไซต์นี้ได้
                เว็บไซต์ขอสงวนสิทธิ์ในการเปลี่ยนแปลงแก้ไข
                ตลอดจนเพิ่มหรือลบบางส่วนของข้อตกลงและเงื่อนไขในการใช้งานนี้ได้ตลอดเวลา
                การเปลี่ยนแปลงจะมีผลเมื่อทางเราได้เปลี่ยนแปลงข้อบังคับดังกล่าวบนเว็บไซต์โดยไม่จำเป็นจะต้องมีการแจ้งให้ทราบล่วงหน้า
                กรุณาตรวจสอบข้อตกลงและเงื่อนไขในการใช้งานเป็นประจำสำหรับการปรับปรุงข้อมูลต่างๆ
                หากท่านใช้งานเว็บไซต์นี้หลังจากการเปลี่ยนแปลงข้อตกลงและเงื่อนไขในการใช้งาน
                ถือว่าท่านได้ยอมรับข้อเปลี่ยนแปลงนั้นโดยปริยาย
                หากท่านมีคำถามหรือความเห็น หรือหากมีข้อความใดที่ไม่เข้าใจ
                ท่านสามารถส่งอีเมล์ถึงเราได้ที่ admin@blushingowlstudio.com
              </p>
              <br />
              <!-- การใช้งานเว็บไซต์ -->
              <p class="title is-4">การใช้งานเว็บไซต์</p>
              <p>
                เราอนุญาตให้ท่านใช้งานเว็บไซต์นี้ได้ โดยที่ท่านต้องยอมรับ
                ข้อตกลงและเงื่อนไขในการใช้เว็บไซต์นี้
                ซึ่งอยู่ภายใต้ข้อตกลงและเงื่อนไขที่อธิบายไว้ตามวัตถุประสงค์ของการช้อปปิ้ง
                สำหรับรายการสินค้าส่วนบุคคลที่ขายบนเว็บไซต์
                ซึ่งใช้ในเชิงพาณิชย์หรือในนามของบุคคลที่สามใดๆ ก็ตาม
                ล้วนเป็นสิ่งต้องห้าม
                เว้นแต่ได้รับอนุญาตอย่างชัดเจนจากทางเราล่วงหน้า
                การละเมิดข้อตกลงและเงื่อนไขเหล่านี้ส่งผลต่อไปในการเพิกถอนใบอนุญาตในวรรคนี้ได้ทันทีโดยไม่ต้องแจ้งให้ท่านทราบล่วงหน้า
                <br />
                <br />
                เนื้อหาที่ปรากฏบนเว็บไซต์นี้มีจุดประสงค์เพื่อให้ข้อมูลเท่านั้น
                การส่งหรือแสดงความคิดเห็นบนเว็บไซต์นี้เป็นข้อคิดเห็นของแต่ละโพสต์โดยแต่ละบุคคล
                ซึ่งเนื้อหาดังกล่าวไม่ได้สะท้อนความคิดเห็นของเว็บไซต์แต่ประการใด
                <br />
                <br />
                การบริการและคุณสมบัติบางอย่างที่เกี่ยวข้องกับการลงทะเบียนสมาชิกหรือลงทะเบียนเพื่อขอรับข้อมูลเพิ่มเติมอาจปรากฏอยู่บนเว็บไซต์
                ซึ่งท่านจำเป็นต้องยินยอมที่จะให้ข้อมูลที่ถูกต้องและเป็นข้อมูลที่ปรับปรุงล่าสุดอยู่เสมอ
                และทันทีที่อัพเดตข้อมูลดังกล่าวถ้ามีการเปลี่ยนแปลงใดๆ
                <br />
                <br />

                ผู้ใช้เว็บไซต์ทุกคนเป็นผู้รับผิดชอบต่อการเก็บรหัสผ่านและระบุบัญชีอื่นที่ปลอดภัยและมีความปลอดภัย
                เจ้าของบัญชีคือผู้ที่ต้องรับผิดชอบต่อการกระทำทั้งหมดที่เกิดขึ้นภายใต้รหัสผ่านหรือบัญชีดังกล่าว
                นอกจากนี้หากท่านไม่สามารถจำรหัสผ่านหรือบัญชีของท่านได้
                ทางเว็บไซต์จะไม่รับผิดชอบทั้งโดยตรงหรือโดยอ้อม
                ในการสูญเสียหรือความเสียหายที่เกิดขึ้นชนิดที่เป็นผลจากพฤติกรรมใดๆ
                หรือความเกี่ยวข้องกับความล้มเหลวต่างๆ
                ของท่านที่สอดคล้องกับในส่วนนี้
                <br />
                <br />

                ในระหว่างขั้นตอนการลงทะเบียน
                ท่านยินยอมที่จะได้รับอีเมล์ส่งเสริมการขายจากเว็บไซต์
                ซึ่งท่านก็สามารถเลือกที่จะไม่รับอีเมล์ส่งเสริมการขายได้
                โดยการคลิกลิ้งค์ที่ด้านล่างของอีเมล์ส่งเสริมการขายนั้นๆ
              </p>
              <br />
              <!-- การนำไปใช้ส่วนตัว -->
              <p class="title is-4">การนำไปใช้ส่วนตัว</p>
              <p>
                การใช้ข้อมูลในไซต์นี้เป็นการใช้เพื่อเป็นข้อมูลเท่านั้น
                ท่านตกลงว่าจะไม่แจกจ่าย ตีพิมพ์ ส่งผ่าน เปลี่ยนแปลง แสดง
                หรือสร้างเนื้องานเพิ่มเติมหรือใช้เนื้อหาที่ปรากฏอยู่ในเว็บไซต์นี้ไม่ว่าจะในลักษณะใดๆ
                ก็ตาม ท่านจะต้องไม่ทำสำเนา แจกจ่าย
                หรือจัดแสดงข้อมูลที่ปรากฏอยู่ในเว็บไซต์นี้ไม่ว่าทั้งหมดหรือบางส่วนให้แก่บุคคลภายนอก
                ท่านจะต้องไม่เชื่อมต่อหรือลิ้งค์ (Link) หรือทำซ้ำ/เลียน (Mirror)
                ส่วนใดของไซต์ โปรดทราบว่าเราไม่อนุญาตให้ท่านใช้ ทำซ้ำ
                หรือแจกข้อมูลใดก็ตามโดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรจากเรา
                การละเมิดสิทธิ์เหล่านี้จะนำไปสู่การดำเนินคดีตามความเหมาะสม
                ท่านตกลงจะชดใช้ ต่อสู้คดี และไม่ทำให้ บลัชชิ่ง เอาล์ สตูดิโอ
                ต้องได้รับความเสียหายจากการใช้ข้อมูลใดๆ
                จากเว็บไซต์โดยที่ไม่ได้รับอนุญาต
                ท่านยอมรับว่าการใช้เนื้อหาสาระและข้อความต่างๆ
                ที่ปรากฏในเว็บไซต์นี้โดยไม่ได้รับอนุญาตอาจทำให้เกิดความเสียหายที่ถึงขั้นแก้ไขไม่ได้ต่อ
                บลัชชิ่ง เอาล์ สตูดิโอ และในกรณีที่เป็นการใช้โดยไม่ได้รับอนุญาต
                เรามีสิทธิ์ที่จะได้รับการคุ้มครองจากศาล
                นอกเหนือไปจากการเยียวยาชดใช้ตามกฎหมายหรือด้วยความเป็นธรรมในฐานะเจ้าของ
              </p>
              <br />
              <!-- การกรอกข้อมูลเข้าสู่ระบบ -->
              <p class="title is-4">การกรอกข้อมูลเข้าสู่ระบบ</p>
              <p>
                ข้อมูลทั้งหมดที่ท่านส่งไปยังเว็บไซต์ และ/หรือ
                ให้ไว้กับเราซึ่งรวมถึงแต่ไม่จำกัดเฉพาะ
                คำถามรีวิวความคิดเห็นและข้อเสนอแนะ (รวมเรียกว่า "การส่งข้อมูล")
                จะกลายเป็นข้อมูลของทางบริษัทฯ แต่เพียงผู้เดียว
                และข้อมูลดังกล่าวทั้งหมดจะต้องไม่ถูกส่งกลับไปยังท่าน
                และเมื่อท่านแสดงความคิดเห็นใดๆในเว็บไซต์ของเรา
                ท่านยินยอมให้เราสามารถใช้ชื่อบัญชีที่ท่านส่งในการเชื่อมต่อกับความคิดเห็นดังกล่าวแสดงความคิดเห็นหรือเนื้อหาอื่นๆได้ทันที
                และท่านจะต้องไม่ใช้อีเมล์เท็จหรือหลอกว่าเป็นคนอื่นที่ไม่ใช่ตนเองหรือทำให้เกิดความเข้าใจผิดต่อบริษัทฯ
                หรือบุคคลที่สามโดยเด็ดขาด
                และทางเราจะไม่ลบหรือแก้ไขสิ่งส่งมอบโดยท่านทั้งนั้น
              </p>
              <br />
              <!-- การยอมรับในการสั่งซื้อ การพรีออเดอร์ และข้อกำหนดของราคา -->
              <p class="title is-4">
                การยอมรับในการสั่งซื้อ การพรีออเดอร์ และข้อกำหนดของราคา
              </p>
              <p>
                เราขอสงวนสิทธิ์ในการปฏิเสธการทำธุรกรรมที่ผิดเงื่อนไข
                ซึ่งมีลักษณะการดำเนินการใดๆ ดังต่อไปนี้; ขายส่งหรือตัวแทนจำหน่าย
                ธุรกรรมที่เป็นการทุจริตและไม่ถูกต้อง
                รวมถึงธุรกรรมที่กระทำด้วยตนเองหรือหรือด้วยเครื่องมืออัตโนมัติที่เป็นการทำธุรกรรมที่เป็นการทุจริต
                <br />
                <br />
                เราพยายามและมุ่งมั่นที่จะให้ข้อมูลราคาสินค้าที่ถูกต้องที่สุดภายในเว็บไซต์สำหรับผู้ใช้งานเว็บไซต์ของเรา
                แต่ข้อผิดพลาดที่อาจยังคงเกิดขึ้นเช่นกรณีที่ราคาของรายการที่แสดงไม่ถูกต้องบนเว็บไซต์
                ดังนั้นเราขอสงวนสิทธิ์ในการปฏิเสธหรือยกเลิกคำสั่งใดๆ
                ในกรณีที่รายการใดรายการหนึ่งที่มีราคาที่ผิดหรือแตกต่างจากความจริง
                เราขอสงวนสิทธิ์ในการปฏิเสธหรือยกเลิกคำสั่งซื้อดังกล่าวหรือไม่ว่าคำสั่งได้รับการยืนยันและ/หรือ
                มีการตัดเงินบัญชีบัตรเครดิตของท่านเรียบร้อยแล้วก็ตาม
              </p>
              <br />
              <!-- เครื่องหมายการค้าและลิขสิทธิ์ -->
              <p class="title is-4">
                เครื่องหมายการค้าและลิขสิทธิ์
              </p>
              <p>
                ข้อมูลบนเว็บไซต์ทั้งหมดเป็นทรัพย์สินทางปัญญาของทางบริษัทฯ
                ไม่ว่าจะจดทะเบียนหรือไม่จดทะเบียนทรัพย์สินทางปัญญา
                เนื้อหาข้อมูลบนเว็บไซต์และการออกแบบเว็บไซต์ซึ่งรวมถึงข้อความกราฟฟิค,
                ซอฟต์แวร์, ภาพถ่าย, วิดีโอ, เพลง,
                เสียงและการคัดสรรและการจัดเรียง
                รวมถึงซอฟแวร์ซอร์สโค้ดและซอฟต์แวร์ที่รองรับจะยังคงเป็นทรัพย์สินของเรา
                เนื้อหาทั้งหมดของเว็บไซต์นี้ยังได้รับความคุ้มครองตามกฎหมายลิขสิทธิ์เป็นงานส่วนรวมภายใต้กฎหมายลิขสิทธิ์ของไทยและอนุสัญญาระหว่างประเทศ
                ขอสงวนสิทธิ์ทั้งหมดโดยชอบธรรม
              </p>
              <br />
              <!-- กฎหมายที่ใช้บังคับ -->
              <p class="title is-4">
                กฎหมายที่ใช้บังคับ
              </p>
              <p>
                ข้อตกลงและเงื่อนไขนี้จะต้องถูกตีความและควบคุมโดยกฎหมายที่ถูกบังคับใช้ในราชอาณาจักรไทย
              </p>
              <br />
              <!-- การเลิกใช้ -->
              <p class="title is-4">
                การเลิกใช้
              </p>
              <p>
                เราขอสงวนสิทธิ์ในการยกเลิกข้อตกลงและเงื่อนไข
                หรือสั่งเพิกถอนการใดหรือทั้งหมดของสิทธิ์ซึ่งท่านได้รับภายใต้ข้อตกลงและเงื่อนไขดังกล่าวได้ตลอดเวลา
                เมื่อสิ้้นสุดของข้อตกลงนี้ท่านยอมรับที่จะยกเลิกการเข้าถึงข้อมูลการใช้งานในเว็บไซต์เราทั้งหมด
                และการใช้เว็บไซต์ในทั้งหมดหรือบางส่วนนี้
                การสิ้นสุดของข้อตกลงนี้จะไม่กระทบกระเทือนสิทธิ์ตามกฎหมายและข้อผูกพัน
                (รวมถึง แต่ไม่จำกัดเฉพาะ ข้อผูกพันการชำระเงิน)
                ของกิจการที่เกิดขึ้นก่อนวันที่ยกเลิก
                ท่านยอมรับว่าเว็บไซต์จะไม่รับผิดชอบต่อท่านหรือต่อบุคคลอื่นใดที่เป็นผลมาจากการระงับดังกล่าวใดๆ
                หรือเลิกจ้าง หากท่านไม่พอใจกับเว็บไซต์หรือไม่พอใจกับเงื่อนไขใดๆ
                ตามเงื่อนไข และกฎกติกา นโยบาย แนวทาง
                ท่านมีสิทธิ์ที่จะหยุดการใช้งานเว็บไซต์เราได้โดยทันที
              </p>
              <br />
              <!-- นโยบายและเงื่อนไขการคืนเงิน -->
              <p class="title is-4">
                นโยบายและเงื่อนไขการคืนเงิน
              </p>
              <p>
                1 ลูกค้าสามารถขอคืนสินค้าให้กับ บลัชชิ่ง เอาล์ สตูดิโอ
                ได้ในกรณีที่เกิดข้อผิดพลาดดังต่อไปนี้<br />
                <span style="padding-right: 1rem" />1.1
                ได้รับสินค้าผิดรุ่นที่ได้สั่งไว้<br />
                <span style="padding-right: 1rem" />1.2
                ได้รับสินค้าผิดสีหรือรูปแบบที่ท่านได้สั่งไว้<br />
                <span style="padding-right: 1rem" />1.3
                บริษัทไม่สามารถจัดส่งสินค้าที่ท่านสั่งซื้อได้<br />
                หากเกิดข้อผิดพลาดเหล่านี้
                เรายินดีให้ท่านเปลี่ยนสินค้าหรือคืนเงิน
                และเราจะรับผิดชอบค่าจัดส่งสินค้ากลับมาให้เรา
                พร้อมรับผิดชอบค่าจัดส่งสินค้าไปให้ท่านใหม่<br />
                <br />
                2 ท่านสามารถแจ้งคืนสินค้าได้และท่านจะต้องส่งสินค้าคืนภายใน 14
                วัน นับตั้งแต่วันที่ท่านได้รับสินค้าจากเรา
                <br />
                <br />
                3 สินค้าที่รับเปลี่ยนคืนจะต้องอยู่ในสภาพเดิมกับที่ท่านได้รับ
                โดยไม่ผ่านการใช้งาน หรือแกะใช้งานใดๆ
                ดังนั้นโปรดแพ๊คสินค้ากลับมาให้เราอย่างระมัดระวัง
                เพื่อให้สินค้าและแพคเกจ ที่ส่งกลับมาอยู่ในสภาพดี ไม่ชำรุดเสียหาย
                <br />
                <br />
                4 เมื่อท่านต้องการเปลี่ยนหรือคืนสินค้าจากกรณีดังกล่าว
                กรุณาติดต่อที่ admin@blushingowlstudio.com และโปรด แจ้งชื่อ
                ที่อยู่ เบอร์ติดต่อ อีเมล
                พร้อมทั้งรุ่นรายละเอียดที่ต้องการเปลี่ยนคืน
                เมื่อทางเราได้รับเรื่องจะรีบดำเนินการ
                และติดต่อท่านทางเบอร์โทรศัพท์ที่ท่านได้แจ้งไว้
                <br />
                <br />
                5 เราไม่รับการแลกเปลี่ยน/คืนในกรณีต่อไปนี้:<br />
                <span style="padding-right: 1rem" /> 5.1
                สินค้าที่ไม่ได้ลิขสิทธิ์จัดจำหน่ายจาก บลัชชิ่ง เอาล์ สตูดิโอ<br />
                <span style="padding-right: 1rem" />5.2 สินค้าที่ได้ทำการแกะ ฉีก
                เปิด หรือใช้แล้ว<br />
                <span style="padding-right: 1rem" />5.3 สินค้าที่มีความเสียหาย
                หรือความเสียหายอันเนื่องมาจากการแพ็คสินค้าส่งคืน บลัชชิ่ง เอาล์
                สตูดิโอ ของลูกค้าเอง<br />
                <span style="padding-right: 1rem" />5.4
                ลูกค้าไม่ได้ส่งสินค้ากลับคืนมาให้เราภายใน 14 วัน
                หลังจากวันที่ได้รับสินค้า<br />
                <span style="padding-right: 1rem" />5.5
                สินค้าที่เป็นของแถมหรือสินค้าร่วมรายการพิเศษแบบมีเงื่อนไข<br />
                <br />
                6 ในกรณีการคืนเงิน
                ทางเราจะทำการคืนเงินให้ท่านผ่านช่องทางที่ท่านได้ชำระกับเรา ภายใน
                30 วัน
                <br />
                <br />
                7 ท่านไม่สามารถยกเลิกการสั่งซื้อหรือพรีออเดอร์ได้
                เมื่อมีการชำระเงินค่าสั่งซื้อหรือพรีออเดอร์เข้ามาแล้ว<br />
              </p>
              <br />
              <!-- ข้อมูลองค์กร -->
              <p class="title is-4">
                ข้อมูลองค์กร
              </p>
              <p>
                ที่อยู่จดทะเบียน : บริษัท บลัชชิ่ง เอาล์ สตูดิโอ จำกัด
                <br />
                เลขที่ 18/93 ซ.คู้บอน 27 ถ.คู้บอน แขวงท่าแร้ง เขตบางเขน กทม.
                10220
                <br />
                ทะเบียนพาณิชย์เลขที่ 0-1055-61122-29-0
              </p>
            </section>
          </div>
          <div v-else class="column">
            <section class="section has-text-left">
              <!-- เงื่อนไขการใช้งานเว็บไซต์ -->
              <p class="title is-4">Website Terms and Conditions</p>
              <p>
                By accessing this website, you confirm that you understand all
                “Terms and condions”. If you do not accept these terms and
                conditions of use, you will not be able to use this website. The
                website reserves the right to make changes. The website reserves
                the right to make changes as well as to add or delete portions
                of these Terms of Use at any time. The change will be effective
                when we change the regulations on the website without prior
                notice. Please check the terms of use regularly for updates. If
                you use this website after changing the terms and conditions of
                use, assume that you accept the change by default. If you have
                questions or comments Or if there is any message that is not
                understood You can email us at admin@blushingowlstudio.com
              </p>
              <br />
              <!-- การใช้งานเว็บไซต์ -->
              <p class="title is-4">Website Usage</p>
              <p>
                We authorized you the access to this website, which you must
                accept all terms and conditions for using this website. Under
                which terms and conditions described in accordance with the
                purpose for shopping only. For personal product listings sold on
                the website, used commercially, or on behalf of any third party
                are all forbidden unless expressively permitted by us in
                advance. Violations of these terms and conditions can result in
                immediate termination of this license without notifying you in
                advance.
                <br />
                <br />
                The content displayed on this website is for informational
                purposes only. Submitting or commenting on this website is an
                opinion of each post by an individual. Which the content does
                not reflect the opinion of the website in any way. Some services
                and features related to member registration or registration for
                additional information may appear on the website. You must agree
                to provide correct and updated information at all times. And
                immediately update the said information if there are any
                changes.
                <br />
                <br />
                Every website user is responsible for keeping passwords and
                identifying other accounts that are safe and secure. The account
                owner is the person responsible for all actions that occur under
                that password or account. In addition, if you are unable to
                remember your password or the account. The website is not
                responsible either directly or indirectly. In any type of loss
                or damage that results from behavior or related to various
                failures from you in accordance with this section.
                <br />
                <br />
                During the registration process You agree to receive promotional
                emails from the website. Which you can choose not to receive
                promotional emails by clicking the link at the bottom of that
                promotional email.
              </p>
              <br />
              <!-- การนำไปใช้ส่วนตัว -->
              <p class="title is-4">Personal Usage</p>
              <p>
                The use of information on this site is for informational
                purposes only. You agree that you will not distribute, publish,
                transmit, change, display or create additional content or use
                content appearing on this website in any manner. You must not
                copy, distribute or display the information contained on this
                website, whether in whole or in part, to third parties. You must
                not connect or link or reproduce/mirror any part of the site.
                Please note that we do not allow you to use reproduce or
                distribute any information without our written permission.
                Violation of these rights will lead to appropriate action. You
                agreed to pay the case and do not cause Blushing Owl Studio to
                be damaged by using any data from the website without
                permission. You agree that the use of content and statements
                appearing on this website without permission may cause
                irreparable damage to Blushing Owl Studios. In the case of
                unauthorized use, we have the right to be protected by the
                court. In addition to legal remedies or fairness as owners.
              </p>
              <br />
              <!-- การกรอกข้อมูลเข้าสู่ระบบ -->
              <p class="title is-4">Entering Login Information</p>
              <p>
                All information you submit to the website and/or provide to us,
                including but not limited to questions, reviews, comments and
                suggestions (collectively referred to as "data transmission");
                will become the company's sole information and all such
                information must not be sent back to you. And when you leave any
                comments on our website, you consent to us being able to use the
                account name you submitted in connection with the said comments
                immediately. You must not use false emails or pretend to be
                someone other than you or causing misunderstandings to the
                company or third parties. Such action is strictly prohibited,
                and we will not delete or modify anything delivered by you.
              </p>
              <br />
              <!-- การยอมรับในการสั่งซื้อ การพรีออเดอร์ และข้อกำหนดของราคา -->
              <p class="title is-4">
                Order Acceptance, Pre-order, and Price Specification
              </p>
              <p>
                We reserve the right to refuse illegal transactions which has
                any of the following actions; Wholesale or dealer Fraudulent and
                invalid transactions Including transactions conducted in person
                or by automated tools that constitute fraudulent transactions.
                We try and strive to provide the most accurate price information
                on the website for our website users. But errors may still
                occur, for example, if the price of the item displayed is
                incorrect on the website; we reserve the right to refuse or
                cancel any orders. In the case that one item has the incorrect
                price or different from the actual price, we reserve the right
                to refuse or cancel the said order or whether the order has been
                confirmed and/or there is a successful debit of your credit card
                account.
              </p>
              <br />
              <!-- เครื่องหมายการค้าและลิขสิทธิ์ -->
              <p class="title is-4">
                Trademarks and Copyrights
              </p>
              <p>
                All information on the website is the intellectual property of
                the company. Whether registered or not registering intellectual
                property website content and website design, including text,
                graphics, software, photos, videos, music, audio, and selection
                and arrangement including software, source code and compatible
                software will remain our property. All content of this website
                is still protected by copyright as a collective work under Thai
                copyright law and international conventions. We reserve all
                rights rightfully.
              </p>
              <br />
              <!-- กฎหมายที่ใช้บังคับ -->
              <p class="title is-4">
                Governing law
              </p>
              <p>
                These terms and conditions must be interpreted and governed by
                the laws enforced in the Kingdom of Thailand.
              </p>
              <br />
              <!-- การเลิกใช้ -->
              <p class="title is-4">
                Cancellation
              </p>
              <p>
                We reserve the right to cancel the terms and conditions. Or to
                cancel any or all of the rights you receive under these terms
                and conditions at any time. At the end of this agreement, you
                agree to cancel all access to the data on our website. And the
                use of this website in whole and not in part.The termination of
                this agreement will not affect legal rights and obligations
                (including but not limited to payment commitment) of the
                business that occurred before the cancellation date. You agree
                that the website will not be responsible to you or to anyone
                else resulting from any such suspension or dismissal. If you are
                not satisfied with the website or not satisfied with any
                conditions, policies, and guidelines; you have the right to stop
                using our website immediately.
              </p>
              <br />

              <!-- ข้อมูลองค์กร -->
              <p class="title is-4">
                Corporate information
              </p>
              <p>
                Registered Address: Blushing Owl Studio Company Limited
                <br />
                18/93 Soi KooBon 27, KooBon Road, Kwang Tahrang, Ket Bangken,
                Bangkok 10220
                <br />
                Commercial registration number 0-1055-61122-29-0
              </p>
            </section>
          </div>

          <!-- END : T&C CONTENT  -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "TermsAndConditions",
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style></style>
